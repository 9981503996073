<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__archivepre">
      <my-container :leftWidth="350">
        <template v-slot:left>
          <div class="tree-container">
            <vxe-table ref="xtree" resizable :loading="treeLoading" show-overflow
                height="auto" border="none" :show-header="false" :tooltip-config="{theme: 'light'}"
                :row-config="rowConfig" :tree-config="treeConfig" :data="treeData" :menu-config="treeMenu"
                @menu-click="contextMenuClick"
                cell-class-name="pointer-cursor"
                @cell-click="treeCellClick">
              <vxe-column field="name" width="700" tree-node>
                <template v-slot="{row}">
                  <span v-if="row.archiveType == archiveType.catalog" style="color: #0e9d6a">{{ row.name }}</span>
                  <span v-else-if="row.archiveType == archiveType.dir" style="color: #c07a17">{{ row.name }}</span>
                  <span v-else>{{ row.name }}</span>
                </template>
              </vxe-column>
            </vxe-table>
          </div>
        </template>
        <template v-slot:right>
          <el-tabs v-model="tabActiveName" type="border-card" :style="{height: rightTabsHeight + 'px'}">
            <el-tab-pane :label="labelTitle" name="tab1" key="tab1">
              <div style="margin-left: 10px">
                <!-- <el-button v-if="canEditCatalog()" type="primary" icon="el-icon-edit-outline" size="mini" @click="openUpdateCatalog(dataForm)" style="margin-bottom: 3px; margin-left: 2px">修改</el-button> -->
                <catalog-view ref="catalogView" :style="{height: tabContentHeight + 'px', 'overflow-y': 'auto'}"></catalog-view>
              </div>
            </el-tab-pane>
            <el-tab-pane v-if="showQcFileTab()" label="文件信息" name="tab2" key="tab2" style="margin-left: 10px">
              <archive-file-list ref="archiveFileList" :height="fileTableHeight"></archive-file-list>
              <el-button v-if="canUploadFiles()" @click="openQcFileDialog(dataForm)" type="primary" size="mini" style="position: absolute; left: 189px; top: 1px">
                选择质检资料
              </el-button>
              <el-button v-if="canSubmit()" @click="submitPreArchive" type="warning" size="mini" icon="el-icon-check" style="position: absolute; left: 290px; top: 1px">
                提交组卷
              </el-button>
            </el-tab-pane>
          </el-tabs>
        </template>
      </my-container>
      <catalog-add-or-update v-if="catalogAddOrUpdateVisible" ref="catalogAddOrUpdate" @refreshData="refreshTreeNode" @close="closeDialogHandle"></catalog-add-or-update>
      <!-- 选择质检资料 -->
      <archive-qcfile-select v-if="qcFileSelectVisible" ref="qcFileSelect" @submit="qcFileSelectSubmit" @close="closeDialogHandle"></archive-qcfile-select>
    </div>
  </el-card>
</template>
<script>
/**
 * 【预组卷管理】
 * 1、允许在分类大纲下新建预组卷目录，也允许在分类大纲下新增档案（这里的档案对应着组卷管理中的卷内文件表）
 * 2、允许在预组卷目录下新建子目录，也允许在预组卷目录下新增档案
 */
import CatalogAddOrUpdate from './archivepre-catalog-add-or-update'
import CatalogView from './archivepre-catalog-view'
import ArchiveFileList from './archive-file-list'
import ArchiveQcfileSelect from "./archive-qcfile-select"
import mixinViewModule from "@/mixins/view-module";

export default {
  mixins: [mixinViewModule],
  components: {
    CatalogAddOrUpdate, ArchiveFileList, CatalogView, ArchiveQcfileSelect
  },
  data() {
    return {
      mixinViewModuleOptions: {
        deleteURL: '',
        deleteSuccessCallback: this.deleteSuccessCallback,
        deleteWithRelatedFiles: false
      },
      dataForm: {}, // 当前节点数据（row）
      treeLoading: false,
      treeData: [],
      rowConfig: {
        isCurrent: true,
        isHover: true,
        useKey: true,
        height: 27,
        keyField: 'id'
      },
      treeConfig: {
        transform: true,// 自动将列表转成树结构
        showLine: true,
        lazy: true,
        iconOpen: 'vxe-icon-square-minus',
        iconClose: 'vxe-icon-square-plus',
        parentField: 'pid',
        hasChild: 'hasChildren',
        loadMethod: this.loadChildren
      },
      treeMenu: {
        body: {
          options: [
            [
              {code: 'add', name: '新增目录', prefixIcon: 'vxe-icon-add', visible: false},
              {code: 'addArchive', name: '新增档案', prefixIcon: 'vxe-icon-add', visible: false},
              {code: 'update', name: '修改', prefixIcon: 'vxe-icon-edit', visible: false},
              {code: 'delete', name: '删除', prefixIcon: 'vxe-icon-delete', visible: false},
            ]
          ]
        },
        visibleMethod: this.visibleMenu
      },
      archiveType: { // 节点类型
        outline: 1, // 分类大纲
        catalog: 2, // 用户自定义的预组卷目录
        dir: 3 // 档案（即对应着组卷管理的卷内目录）
      },
      outlineTreeData: [], // 当前用户权限的分类大纲节点（包括分支节点、叶子节点）
      catalogAddOrUpdateVisible: false,
      qcFileSelectVisible: false,
      tabActiveName: 'tab2',
      rightTabsHeight: 550,
      tabContentHeight: 485,
      fileTableHeight: 385,
      labelTitle: '目录信息'
    }
  },
  mounted() {
    this.rightTabsHeight = window.innerHeight - 140
    this.tabContentHeight = this.rightTabsHeight - 65
    this.fileTableHeight = this.rightTabsHeight - 165
  },
  created() {
    this.getOutlineTreeData()
  },
  methods: {
    // 获取当前用户权限内的分类大纲节点（包括分支节点、叶子节点）
    getOutlineTreeData() {
      this.treeLoading = true
      this.$http.get(`/mps/archiveOutline/preTree`).then(({data: res}) => {
        if (res.code != 0) {
          return this.$message.error(res.msg)
        }
        this.outlineTreeData = res.data
        this.sortByCodeAndSortNo(this.outlineTreeData)
        this.treeData = res.data.filter(data => data.pid == 0)
        // this.sortByCodeAndSortNo(this.treeData)
        if (this.treeData && this.treeData.length > 0) {
          this.$nextTick(() => {
            // 默认展开第一个节点、选中第一个节点
            this.treeAutoExpandAndClick(this.treeData[0].id)
          })
        }
      }).catch(() => {
        this.$message.error('加载出错')
      }).finally(() => {
        this.treeLoading = false
      })
    },

    sortByCodeAndSortNo(arr) {
      arr.sort((a, b) => {
        if (a.code && b.code) {
          if (a.code < b.code) {
            return -1
          } else {
            return 1
          }
        } else {
          return a.sortNo - b.sortNo
        }
      })
    },

    // 左侧树节点的点击事件
    treeCellClick({row, triggerTreeNode}) {
      if (!triggerTreeNode) {
        this.$refs.xtree.setCurrentRow(row)
        this.$refs.xtree.setTreeExpand(row, true)
        this.dataForm = {...row}
        
        this.$nextTick(() => {
          // 刷新右侧的数据
          this.reloadTabData()
        })
      }
    },

    // 异步加载案卷目录、卷内目录
    loadChildren({row}) {
      if (row.archiveType == this.archiveType.outline) {// 加载分类大纲
        if (row.outlineLeaf) {// 如果是分类大纲的叶子节点，则加载用户自己创建的预组卷目录
          return this.loadCatalogChildren(row.id)
        } else {// 如果是分类大纲的分支节点，则继续加载分类大纲的下级节点
          return new Promise(resolve => {
            let childrenData = this.outlineTreeData.filter(data => data.pid == row.id)
            resolve(childrenData)
          })
        }
      } else if (row.archiveType == this.archiveType.catalog) {// 如果预组卷目录，则加载下级节点
        return this.loadCatalogChildren(row.id)
      }
    },

    // 加载用户自己创建的预组卷目录
    loadCatalogChildren(pid) {
      return new Promise((resolve, reject) => {
        this.$http.get(`/mps/archivePreCatalog/children/${pid}`).then(({data: res}) => {
          if (res.code !== 0) {
            reject()
            return this.$message.error(res.msg)
          }
          resolve(res.data)
        }).catch(() => {
          reject()
        })
      })
    },

    // 判断预组卷节点（Catalog）的类型是档案还是普通的目录
    getArchiveType(nodes) {
      let archiveType = 2 // 2：预组卷目录，3：档案
      if (nodes) {
        nodes.forEach(n => {
          if (n.archiveType == 3) {
            archiveType = 3
          }
        })
      }
      return archiveType
    },

    // 显示右键菜单选项
    visibleMenu({type, options, row}) {
      this.treeCellClick({row: row, triggerTreeNode: false})
      this.$refs.xtree.setTreeExpand(row, true).then(()=>{
        if (type == 'body' && row) {
          options.forEach(list => {
            list.forEach(item => {
              item.visible = false // 先隐藏右键菜单项, 然后再根据权限设置是否显示
              if (this.canEditCatalog()) {
                if (row.archiveType == this.archiveType.outline 
                    && row.outlineLeaf) { // 分类大纲的叶子节点
                    let children = row.children
                    if (!children || children.length == 0) {
                      if (['add'].includes(item.code) || ['addArchive'].includes(item.code)) {
                        item.visible = true
                      }
                    } else if (this.getArchiveType(children) == 3) {// 如果当前节点的子孙节点是档案，那么该节点右键只允许新增档案
                      if (['addArchive'].includes(item.code)) {
                        item.visible = true
                      }
                    } else {// 如果当前节点的子孙节点是普通目录，那么该节点右键只允许新增普通目录
                      if (['add'].includes(item.code)) {
                        item.visible = true
                      }
                    }
                } else if (row.archiveType == this.archiveType.catalog) { // 预组卷目录
                  let children = row.children
                  if (!children || children.length == 0) {
                    if (['add'].includes(item.code) 
                        || ['addArchive'].includes(item.code)
                        || ['update'].includes(item.code)
                        || ['delete'].includes(item.code)) {
                      item.visible = true
                    }
                  } else if (this.getArchiveType(children) == 3) {// 如果该目录下已经有了档案，则仅允许新增档案
                    if (['addArchive'].includes(item.code)
                        || ['update'].includes(item.code)
                        || ['delete'].includes(item.code)) {
                      item.visible = true
                    }
                  } else { // 如果节点下面已经有了子目录，则仅允许新增目录
                    if (['add'].includes(item.code)
                        || ['update'].includes(item.code)
                        || ['delete'].includes(item.code)) {
                      item.visible = true
                    }
                  }
                } else if (row.archiveType == this.archiveType.dir) { // 档案（对应的是组卷管理的卷内目录）
                  if (['update'].includes(item.code) || ['delete'].includes(item.code)) {
                    item.visible = true
                  }
                }
              }
            })
          })
        }
      })
      return true
    },

    // 点击右键菜单的某一项
    contextMenuClick({menu, type, row}) {
      if (type == 'body' && row) {
        switch (menu.code) {
          case 'add':
            this.openAddCatalog(row.id, this.archiveType.catalog)
            break
          case 'addArchive':
            this.openAddCatalog(row.id, this.archiveType.dir)
            break
          case 'update':
            this.openUpdateCatalog(row)
            break
          case 'delete':
            this.deleteOne(row)
            break
        }
      }
    },

    // nodeType：2 预组卷目录，3 档案（即对应着组卷管理的卷内目录）
    openAddCatalog(id, nodeType) {
      this.catalogAddOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.catalogAddOrUpdate.dataForm.id = ''
        this.$refs.catalogAddOrUpdate.dataForm.archiveType = nodeType
        this.$refs.catalogAddOrUpdate.dataForm.pid = id
        this.$refs.catalogAddOrUpdate.init()
      })
    },
    openUpdateCatalog(row) {
      if (row.archiveType == this.archiveType.catalog || row.archiveType == this.archiveType.dir) {
        this.catalogAddOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.catalogAddOrUpdate.dataForm.id = row.id
          this.$refs.catalogAddOrUpdate.dataForm.pid = row.pid
          this.$refs.catalogAddOrUpdate.init()
        })
      }
    },
    deleteSuccessCallback({row}) {
      this.treeAutoExpandAndClick(row.pid)
    },
    deleteOne(row) {
      if (row.archiveType == this.archiveType.catalog || row.archiveType == this.archiveType.dir) {
        this.mixinViewModuleOptions.deleteURL = 'mps/archivePreCatalog'
        this.deleteHandle(row.id, {deleteSuccessCallbackArgs: {row}, autoQuery: false})
      }
    },

    // 节点新增/修改后，刷新左侧树节点
    refreshTreeNode(item) {
      if (item.type == 'add') {
        let currentRow = this.$refs.xtree.getRowById(item.mainId)
        currentRow.hasChildren = true
        currentRow.isLeaf = false
        this.treeAutoExpandAndClick(item.mainId)
      } else if (item.type == 'update') {
        let row = this.$refs.xtree.getRowById(item.id)
        row.name = item.name
        if (item.id == this.dataForm.id) {
          this.treeAutoExpandAndClick(item.id)
        }
      }
    },

    // 关闭弹窗
    closeDialogHandle() {
      this.catalogAddOrUpdateVisible = false
      this.qcFileSelectVisible = false
    },

    // 展开并选中左侧树节点
    treeAutoExpandAndClick(rowId) {
      // 找到当前行，重新加载节点,将其选中并触发点击事件
      let currentRow = this.$refs.xtree.getRowById(rowId)
      this.$refs.xtree.clearTreeExpandLoaded(currentRow)
      this.$refs.xtree.setTreeExpand(currentRow)
      this.$refs.xtree.setCurrentRow(currentRow)
      this.treeCellClick({row: currentRow, triggerTreeNode: false})
    },

    // 重新获取右侧各页签中的数据
    reloadTabData() {
      this.tabActiveName = 'tab1'
      this.labelTitle = '目录信息'
      
      // 如果选中的节点是分类大纲的叶子节点，或者预组卷目录，则直接查询该节点下面的档案列表（根据pid，查询archiveType为3的叶子节点）
      if (this.dataForm.archiveType == this.archiveType.catalog 
          || (this.dataForm.archiveType == this.archiveType.outline && this.dataForm.outlineLeaf)) {
          this.$refs.catalogView.dataForm.id = this.dataForm.id
          this.$refs.catalogView.dataForm.archiveType = this.dataForm.archiveType
          this.$refs.catalogView.dataForm.showType = 3
          this.$refs.catalogView.init()
      } else if (this.dataForm.archiveType == this.archiveType.dir) {
        this.labelTitle = '档案信息'
        this.$refs.catalogView.dataForm.id = this.dataForm.id
        this.$refs.catalogView.dataForm.archiveType = this.dataForm.archiveType
        this.$refs.catalogView.dataForm.showType = 2
        this.$refs.catalogView.init()

        // 文件列表
        this.$refs.archiveFileList.canEdit = this.canUploadFiles()
        this.$refs.archiveFileList.refId = this.dataForm.id

        // if (this.dataForm.isLeaf) {
        //   this.$refs.archiveFileList.refId = this.dataForm.id
        // } else {// 点击预组卷目录的分支节点，递归显示下面的所有文件
        //   this.$refs.archiveFileList.refId = ''
        //   this.$http.get(`/mps/archivePreCatalog/selfAndDescendantIds/${this.dataForm.id}`).then(({data: res}) => {
        //     if (res.code === 0) {
        //       // 当前节点以及子孙节点的ID集合
        //       let selfAndDescendantIds = res.data
        //       if (selfAndDescendantIds) {
        //         if (selfAndDescendantIds.length == 1 && selfAndDescendantIds[0] == this.dataForm.id) {// 说明当前节点仍然是叶子节点
        //           this.$refs.archiveFileList.refId = this.dataForm.id
        //         } else {
        //           this.$refs.archiveFileList.loadSelfAndDescendantFiles(selfAndDescendantIds)
        //         }
        //       }
        //     }
        //   })
        // }
        
      } else {
        // 目录信息
        this.$refs.catalogView.dataForm.id = this.dataForm.id
        this.$refs.catalogView.dataForm.archiveType = this.dataForm.archiveType
        this.$refs.catalogView.dataForm.showType = this.dataForm.archiveType
        this.$refs.catalogView.init()
      }
    },
    showQcFileTab() {
      return this.dataForm.archiveType == this.archiveType.dir
    },
    canUploadFiles() {// 只有预组卷目录下面的档案节点（对应组卷管理的卷内目录）允许上传文件
      return this.$hasPermission('mps:archiveprecatalog:edit') && this.dataForm.archiveType == this.archiveType.dir && !this.dataForm.copied
    },
    canEditCatalog() {
      return this.$hasPermission('mps:archiveprecatalog:edit') && !this.dataForm.copied
    },
    canDeleteCatalog() {
      return this.$hasPermission('mps:archiveprecatalog:delete')
    },
    canSubmit() {
      return this.$hasPermission('mps:archiveprecatalog:submit') && this.dataForm.archiveType == this.archiveType.dir && !this.dataForm.copied
    },
    openQcFileDialog() {
      this.qcFileSelectVisible = true
      this.$nextTick(() => {
        this.$refs.qcFileSelect.init()
      })
    },
    // 选择质检资料后提交操作
    qcFileSelectSubmit(selectedData) {
      if (selectedData && selectedData.length > 0) {
        const loading = this.$loading({
          lock: true,
          text: '保存中...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.put('/mps/archiveQcFiles/bindQcFiles', {
          selectedData: selectedData,
          refId: this.dataForm.id
        }).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              // 刷新附件列表
              this.$refs.archiveFileList.refId = null
              this.$nextTick(() => {
                this.$refs.archiveFileList.refId = this.dataForm.id
              })
            }
          })
        }).catch().finally(() => {
          loading.close()
        })
      }
    },

    // 提交组卷
    submitPreArchive() {
      if (this.dataForm.copied) {
        return this.$message.error('该档案已提交过，不允许重复提交！')
      }
      this.$confirm('确定提交组卷吗？').then(() => {
        let preCatalogId = this.dataForm.id
        this.$http.post(`/mps/archiveQcFiles/submit`, [preCatalogId]).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$alert(res.msg, '提交失败', {
              confirmButtonText: '关闭',
            })
          } else {
            // 提交后控制部分按钮
            this.dataForm.copied = true
            this.$refs.xtree.getRowById(preCatalogId).copied = true
            this.$refs.archiveFileList.canEdit = this.canUploadFiles()
            return this.$alert('提交成功！', '操作结果', {
              confirmButtonText: '关闭'
            })
          }
        })
      })
    },
  }
}
</script>

<style scoped>
.tree-container {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 140px);
}
</style>