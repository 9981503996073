<template>
  <el-dialog v-dialog-drag :visible.sync="visible" :title="dialogTitle" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-divider content-position="left">基本信息</el-divider>
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="上级节点" prop="pid">
            <archiveoutline-parent-select v-model="dataForm.pid" :disabledId="dataForm.id"></archiveoutline-parent-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="目录代码" prop="code">
            <el-input v-model="dataForm.code" placeholder="目录代码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="目录名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="目录名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="dataForm.id" prop="sortNo" label="排序序号">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-divider content-position="left">著录信息</el-divider>
    <archive-meta-viewer :dataList="dataForm.metaValList" :readonly="false"></archive-meta-viewer>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import ArchiveoutlineParentSelect from "@/views/modules/mps/archiveoutline-parent-select"
import ArchiveMetaViewer from "@/components/archive-meta-viewer";

export default {
  data () {
    return {
      visible: false,
      dialogTitle: '',
      dataForm: {
        id: '',
        pid: '',
        pids: '',
        pnames: '',
        code: '',
        name: '',
        leaf: '',
        sortNo: '',
        typeId: '',
        tenantCode: '',
        creator: '',
        updater: '',
        createDate: '',
        updateDate: '',
        metaValList: []
      },
    }
  },
  computed: {
    dataRule() {
      return {
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  components: {
    ArchiveoutlineParentSelect,
    ArchiveMetaViewer
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        } else {
          if (this.dataForm.typeId == 1) {
            this.dialogTitle = '新增[预组卷]分类大纲'
          } else if (this.dataForm.typeId == 2) {
            this.dialogTitle = '新增[组卷]分类大纲'
          } else {
            return this.$message.error('缺少目录分类')
          }
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/mps/archiveOutline/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        if (this.dataForm.typeId == 1) {
          this.dialogTitle = '修改[预组卷]分类大纲'
        } else if (this.dataForm.typeId == 2) {
          this.dialogTitle = '修改[组卷]分类大纲'
        } else {
          return this.$message.error('目录分类不正确！')
        }
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/archiveOutline/', this.dataForm).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        })
      })
    }, 1000, { 'leading': true, 'trailing': false })
  }
}
</script>