<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__archiveoutline">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-select v-model="dataForm.typeId" placeholder="请选择目录分类" @change="typeSelectChange">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="addRoot" name="新增根节点" v-if="$hasPermission('mps:archiveoutline:save')" @click="addChildNode({id: 0})"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <vxe-table
          :loading="dataListLoading"
          show-overflow
          :row-config="{useKey:true, isHover: true, isCurrent: true, keyField: 'id'}"
          show-header
          :data="dataList"
          :tree-config="{transform: true, showLine: true, trigger: 'cell', parentField: 'pid', expandRowKeys: expandRowKeys, iconOpen: 'vxe-icon-square-minus', iconClose: 'vxe-icon-square-plus'}">
        <vxe-column field="name" title="目录名称" fixed="left" tree-node min-width="300">
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{ row.name }}</el-button>
          </template>
        </vxe-column>
        <vxe-column field="code" title="目录代码" fixed="left" width="280"></vxe-column>
        <vxe-column title="操作" width="290" align="center" fixed="right">
          <template #default="{row}">
            <table-button role="auth" v-if="$hasPermission('mps:archiveoutline:update')" @click="authorityHandle(row.id)"></table-button>
            <table-button role="addChild" v-if="$hasPermission('mps:archiveoutline:save')" @click="addChildNode(row)"></table-button>
            <table-button role="update" v-if="$hasPermission('mps:archiveoutline:update')" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('mps:archiveoutline:delete')" @click="deleteHandle(row.id)"></table-button>
          </template>
        </vxe-column>
      </vxe-table>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
      <!-- 分配人员权限 -->
      <user-multi-select v-if="authorityVisible" ref="authority" @close="closeAuthorityHandle" @submit="submitAuthority"></user-multi-select>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './archiveoutline-add-or-update'
import ViewPage from './archiveoutline-view'
import XEUtils from 'xe-utils'

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: true,
        getDataListURL: '/mps/archiveOutline/list',
        deleteURL: '/mps/archiveOutline',
        showEmptyDataWarningMessage: true,
        fetchDataSuccessCallback: this.dataSuccessCallback
      },
      dataForm: {
        typeId: 1,
      },
      expandRowKeys: [],
      authorityVisible: false,
      currentRowId: null,
      typeOptions: [
        {
          value: 1,
          label: '预组卷管理目录'
        }, {
          value: 2,
          label: '组卷管理目录'
        }
      ],
    }
  },
  watch: {},
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    // 数据加载成功后的回调
    dataSuccessCallback(dataList) {
      // 展开所有节点
      this.expandRowKeys = XEUtils.map(dataList, item => item.id)
    },
    // 新增子节点
    addChildNode(row) {
      if (!this.dataForm.typeId) {
        return this.$message.error('请选择目录分类')
      }
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.pid = row.id
        this.$refs.addOrUpdate.dataForm.typeId = this.dataForm.typeId
        this.$refs.addOrUpdate.init()
      })
    },
    // 获取当前分类大纲节点已授权的人员ID集合
    authorityHandle(outlineId) {
      this.currentRowId = outlineId
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.get(`/mps/archiveOutline/authorityIds/${outlineId}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.authorityVisible = true
        this.$nextTick(() => {
          this.$refs.authority.selectedList = res.data
          this.$refs.authority.init()
        })
      }).catch().finally(() => {
        loading.close()
      })
    },
    closeAuthorityHandle() {
      this.authorityVisible = false
    },
    // 提交该节点的人员授权结果
    submitAuthority(selectedList) {
      this.$http['put']('/mps/archiveOutline/authority', {
        outlineId: this.currentRowId,
        userIds: selectedList
      }).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 1000
        })
      }).catch()
    },
    
    typeSelectChange() {
      if (this.dataForm.typeId) {
        this.getDataList()
      }
    }

  }
}
</script>