<template>
  <el-dialog v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? '新增' : '修改'" top="5vh" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10" v-if="dataForm.archiveType == 2">
        <el-col :span="12">
          <el-form-item label="目录名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="目录名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="目录编号" prop="code">
            <el-input v-model="dataForm.code" placeholder="目录编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序序号" prop="sortNo" v-if="dataForm.id">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10" v-if="dataForm.archiveType == 3">
        <el-col :span="24">
          <el-form-item label="文件题名" prop="name">
            <el-input v-model="dataForm.name" placeholder="文件题名"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="文件编号" prop="code">
            <el-input v-model="dataForm.code" placeholder="文件编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="责任者" prop="liable">
            <el-input v-model="dataForm.liable" placeholder="责任者" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="成文日期" prop="fileDate">
            <el-date-picker type="date" placeholder="文件形成日期" value-format="yyyy-MM-dd" v-model="dataForm.fileDate" style="width: 100%;" :clearable="!dataForm.id"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="页数" prop="pages">
            <el-input-number v-model="dataForm.pages" controls-position="right" :min="0"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序序号" prop="sortNo" v-if="dataForm.id">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0"></el-input-number>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="档案备注" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" :autosize="{ minRows: 3,maxRows:10 }"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        pid: '',
        archiveType: '', // 2：用户自定义预组卷目录，3：档案（对应着组卷管理的卷内目录）
        code: '',
        name: '',
        sortNo: '',
        liable: '',
        pages: '',
        fileDate: '',
        remark: '',
        sortNo: '',
      }
    }
  },
  computed: {
    dataRule() {
      return {
        code: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        } else {
          // 责任人：当前用户所属单位
          this.dataForm.liable = this.$store.state.user.deptName
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/archivePreCatalog/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/archivePreCatalog/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              let mainId = this.dataForm.pid
              let id = this.dataForm.id ? this.dataForm.id : res.data
              let name = this.dataForm.name
              let type = !this.dataForm.id ? 'add' : 'update' // 新增/修改
              this.$emit('refreshData', {type, id, name, mainId})
            }
          })
        })
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>
