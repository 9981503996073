<template>
  <my-upload v-if="refId" ref="uploader" :refId="refId" :view="!canEdit" :tableMaxHeight="height" :deleteUrl="deleteUrl"></my-upload>
  <el-table
        v-else
        v-loading="dataListLoading"
        :element-loading-text="loadingText"
        :data="dataList"
        highlight-current-row border
        style="width: 100%;"
        :max-height="height"
        size="mini"
        :header-cell-style="{color:'#000000'}">
      <el-table-column prop="name" label="附件名称" align="left" show-overflow-tooltip sortable="custom">
        <template slot-scope="scope">
          <el-button type="text" @click="handleDownload(scope.row.url,scope.row)">{{ scope.row.name }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="createDate" label="上传时间" header-align="center" align="center" show-overflow-tooltip sortable="custom" width="140"></el-table-column>
      <el-table-column prop="creatorName" label="上传人" header-align="center" align="center" show-overflow-tooltip width="110"></el-table-column>
    </el-table>
</template>

<script>
// 档案文件列表
export default {
  data() {
    return {
      refId: '',
      canEdit: false,
      deleteUrl: '/oss/file',

      dataListLoading: false,
      dataList: [],
      loadingText: '',
    }
  },
  props: {
    height: Number
  },
  methods: {
    // 加载显示多个refId的附件（一般是点击分支节点时，显示其子孙节点的附件）
    loadSelfAndDescendantFiles(refIds) {
      this.canEdit = false // 多个refId的时候，不允许编辑，只允许查看附件
      if (refIds) {
        this.$http.post(`/oss/file/listByRefIds`, refIds).then(({data: res}) => {
          if (res.code === 0) {
            this.dataList = res.data
          }
        }).catch()
      } else {
        this.dataList = []
      }
    }
  }
}
</script>
