<template>
  <div>
    <!--<el-divider content-position="left">著录信息</el-divider>-->
    <archive-meta-viewer v-if="dataForm.showType == 1" :dataList="dataForm.metaValList" readonly></archive-meta-viewer>
    <vxe-table
        v-if="dataForm.showType == 2"
        ref="vxeTable"
        stripe
        border
        show-overflow
        :row-config="{keyField: 'id', isHover: true, isCurrent: true}"
        :column-config="{resizable: true}"
        :max-height="tableHeight"
        :data="vxeData">
      <vxe-column field="code" title="案卷编号" width="180"></vxe-column>
      <vxe-column field="name" title="案卷题名" min-width="150"></vxe-column>
      <vxe-column field="liable" title="责任人" min-width="150"></vxe-column>
      <vxe-column field="count" title="卷内件数" width="100"></vxe-column>
      <vxe-column field="pages" title="卷内页数" width="100"></vxe-column>
      <vxe-column field="period" title="保管期限" align="center" width="100">
        <template #default="{ row }">
          {{ $getDictLabel('archive_period', row.period) }}
        </template>
      </vxe-column>
      <vxe-column field="secret" title="密级" align="center" width="100">
        <template #default="{ row }">
          {{ $getDictLabel('archive_secret', row.secret) }}
        </template>
      </vxe-column>
    </vxe-table>
  </div>
</template>

<script>
// 点击分类大纲，直接显示案卷目录
import ArchiveMetaViewer from "@/components/archive-meta-viewer";
export default {
  data() {
    return {
      dataForm: {
        id: '',
        metaValList: [],
        showType: '', // 1：显示分类大纲的著录信息；2：显示案卷目录表格
      },
      tableHeight: 500,
      vxeData: [],
    }
  },
  components: {
    ArchiveMetaViewer
  },
  mounted () {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 160;
    })
  },
  methods: {
    init() {
      this.$nextTick(() => {
        if (this.dataForm.id) {
          if (this.dataForm.showType == 1) {// 查询分类大纲的信息
            this.getInfo()
          } else if (this.dataForm.showType == 2) {// 查询案卷目录
            this.getCatalogList()
          }
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/archiveOutline/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      })
    },

    // 查询案卷目录
    getCatalogList() {
      this.$http.get('/mps/archivePostCatalog/list',
      {
        params: {
          outlineId: this.dataForm.id
        }
      }).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.vxeData = res.data
      })
    },
  }
}
</script>
