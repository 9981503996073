<template>
  <div>
    <el-divider content-position="left">基本信息</el-divider>
    <el-descriptions :column="3" border :labelStyle="labelStyle">
      <el-descriptions-item label="案卷档号">{{ dataForm.code }}</el-descriptions-item>
      <el-descriptions-item label="案卷题名" :span="2">{{ dataForm.name }}</el-descriptions-item>
      <el-descriptions-item label="立卷日期">{{ dataForm.filingDate }}</el-descriptions-item>
      <!-- <el-descriptions-item label="件数">{{ dataForm.cases }}</el-descriptions-item>
      <el-descriptions-item label="总页数">{{ dataForm.pages }}</el-descriptions-item> -->
      <el-descriptions-item label="保管期限">{{ $getDictLabel('archive_period', dataForm.period) }}</el-descriptions-item>
      <el-descriptions-item label="保管密级">{{ $getDictLabel('archive_secret', dataForm.secret) }}</el-descriptions-item>
      <el-descriptions-item label="排序序号">{{ dataForm.sortNo }}</el-descriptions-item>
      <el-descriptions-item label="案卷备注">{{ dataForm.remark }}</el-descriptions-item>
    </el-descriptions>
    <el-divider content-position="left">著录信息</el-divider>
    <archive-meta-viewer :dataList="dataForm.metaValList" readonly></archive-meta-viewer>
  </div>
</template>

<script>
import ArchiveMetaViewer from "@/components/archive-meta-viewer"

export default {
  data() {
    return {
      dataForm: {
        id: '',
        outlineId: '',
        code: '',
        name: '',
        deptId: '',
        filingDate: '',
        cases: '',
        pages: '',
        period: '',
        secret: '',
        remark: '',
        sortNo: '',
        metaValList: []
      },
      labelStyle: {
				'text-align': 'center',
				'width': '130px',
				'word-break': 'keep-all'
			}
    }
  },
  components: {
    ArchiveMetaViewer
  },
  methods: {
    init() {
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/archivePostCatalog/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch()
    },
  }
}
</script>
