<template>
  <div>
    <div v-if="dataForm.showType == 1">
      <el-descriptions :column="2" :labelStyle="labelStyle" border>
        <el-descriptions-item label="目录编号" >{{ dataForm.code }}</el-descriptions-item>
        <el-descriptions-item label="目录名称" >{{ dataForm.name }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div v-else-if="dataForm.showType == 2">
      <el-descriptions  :column="2" :labelStyle="labelStyle" border>
        <el-descriptions-item label="文件编号">{{ dataForm.code }}</el-descriptions-item>
        <el-descriptions-item label="文件题名" :span="2">{{ dataForm.name }}</el-descriptions-item>
        <el-descriptions-item label="责任者">{{ dataForm.liable }}</el-descriptions-item>
        <el-descriptions-item label="成文日期">{{ dataForm.fileDate }}</el-descriptions-item>
        <el-descriptions-item label="页数">{{ dataForm.pages }}</el-descriptions-item>
        <el-descriptions-item label="档案备注" :span="2">{{ dataForm.remark }}</el-descriptions-item>
      </el-descriptions>
      <span style="padding-top: 10px !important; color: green; font-weight: bold;" v-if="dataForm.copied">已提交 <i class="el-icon-check"></i></span>
    </div>
    <div v-else-if="dataForm.showType == 3">
      <el-button v-if="canSubmit()" @click="submitPreArchive" type="warning" size="mini" icon="el-icon-check" style="margin-bottom: 5px;">批量提交组卷</el-button>
      <vxe-table
          ref="vxeTable"
          stripe
          border
          show-overflow
          :row-config="{keyField: 'id', isHover: true, isCurrent: true}"
          :column-config="{resizable: true}"
          :checkbox-config="checkboxConfig"
          :max-height="tableHeight"
          :data="vxeData">
        <vxe-column type="checkbox" width="60" align="center"></vxe-column>
        <vxe-column field="code" title="文件编号" width="220"></vxe-column>
        <vxe-column field="name" title="文件题名"></vxe-column>
        <vxe-column field="pages" title="页数" width="100"></vxe-column>
        <vxe-column field="liable" title="责任者" width="180"></vxe-column>
        <vxe-column field="copied" title="是否提交" align="center" width="120">
          <template #default="{ row }">
            <span v-if="row.copied" style="color: #0e9d6a">已提交</span>
            <span v-else style="color: #c07a17">未提交</span>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
  </div>
</template>
 
<script>
export default {
  data() {
    return {
      dataForm: {
        id: '',
        pid: '',
        code: '',
        name: '',
        liable: '',
        pages: '',
        fileDate: '',
        remark: '',
        sortNo: '',
        copied: false,
        archiveType: '', // 1、分类大纲，2：预组卷目录，3：档案（对应着组卷管理的卷内目录）
        showType: '', // 1、显示编码和名称，2：显示档案表单详情，3：显示档案列表
      },
      tableHeight: 500,
      vxeData: [],
      checkboxConfig: {
        showHeader: true,
        visibleMethod: ({row}) => !row.copied,
        trigger: 'row',
      },

      labelStyle: {
				'text-align': 'center',
				'width': '130px',
				'word-break': 'keep-all'
			}
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 160;
    })
  },
  methods: {
    init() {
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    getInfo() {
      if (this.dataForm.showType == 1) {
        this.getOutlineInfo()
      } else if (this.dataForm.showType == 2) {
        this.getCatalogInfo()
      } else if (this.dataForm.showType == 3) {
        this.getArchives()
      }
    },

    // 查询分类大纲信息
    getOutlineInfo() {
      this.$http.get('/mps/archiveOutline/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      })
    },

    // 查询预组卷目录信息
    getCatalogInfo() {
      this.$http.get('/mps/archivePreCatalog/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      })
    },

    // 查询档案列表
    getArchives() {
      this.$http.get(`/mps/archivePreCatalog/archives/${this.dataForm.id}/${this.dataForm.archiveType}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.vxeData = res.data
      })
    },

    canSubmit() {
      return this.$hasPermission('mps:archiveprecatalog:submit') && this.dataForm.showType == 3
    },

    // 提交组卷
    submitPreArchive() {
      let preCatalogIds = []
      this.$refs.vxeTable.getCheckboxRecords(false).map(item => {
        preCatalogIds.push(item.id)
      })
      if (preCatalogIds.length == 0) {
        return this.$message.error('请选择要提交的档案！')
      }
      this.$confirm('确定提交组卷吗？').then(() => {
        this.$http.post(`/mps/archiveQcFiles/submit`, preCatalogIds).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$alert(res.msg, '提交失败', {
              confirmButtonText: '关闭',
            })
          }
          this.getArchives()
        })
      })
    },
  }
}
</script>